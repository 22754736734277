import React, { useState, useEffect } from 'react';
import { Container, Grid, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead,
  Select,MenuItem, TableRow, Button,InputLabel,FormControl } from '@mui/material';
import { styled } from '@mui/system';
import { useParams } from 'react-router-dom';
import { order_detail, order_status_update } from '../services/order';
import DialogInvoice from './DialogInvoice';

const StyledContainer = styled(Container)({
  marginTop: 20,
  paddingBottom: 20,
});

const StyledHeader = styled(Paper)({
  padding: 20,
  marginBottom: 20,
  textAlign: 'center',
});

const StyledTableContainer = styled(TableContainer)({
  marginTop: 20,
});

const StyledButton = styled(Button)({
  marginTop: 20,
  backgroundColor: '#1976d2',
  color: '#fff',
});

const OrderDetailPage = () => {
  const [openInvoiceDailog, setOpenInvoiceDailog] = React.useState(false);
  const { orderId } = useParams();
  const [status,setStatus] = useState("Pending")
  const [order, setOrder] = useState<any>({});
  const [orderProducts, setOrderProducts] = useState<any[]>([]);
  const [errorMessage,setErrorMessage] = useState({
    message:"",
    isError:false
  })
  useEffect(() => {
    if (orderId) {
      fetchOrderDetail();
    }
  }, [orderId]);

  const fetchOrderDetail = async () => {
    const response: any = await order_detail(orderId);
    setOrder(response?.data?.order || {});
    const transformedData = response?.data?.orderProducts?.map((item:any, index:any) => {
       return {
        id: index + 1,
        name: item.productName,
        attribute: {
          id: item.id,
          productId: item.productId,
          attributeId: item.attributeId,
          mainAttributesId: 1, // Assuming this is a constant
          mrpPrice: item.mrpPrice,
          discount: item.discount,
          price: item.price,
          stockQty: item.qty, // Assuming qty is the stock quantity
          rackPlacementId: item.rackPlacementId,
          attributeName: item.attributeName,
          mainAttributeName: item.mainAttributeName,
          "gst_percentage": item.gst_percentage,
          "gst_price_total": item.gst_price_total,
          "gst_per_price": item.gst_per_price,
          "totalPrice":item.totalPrice,
          "price_per_unit":item.price_per_unit,
          "unit":item.unit
        },
        orderQty: item.qty
      }
  }) || [];
      setOrderProducts([...transformedData]);
      setStatus(response?.data?.order.status)
  };
  const onChangeStatus = async (status:string) => {
    setStatus(status)
    try{
      if(orderId && status){
        let payload={
          status:status
        }
        const response: any = await order_status_update(payload,orderId);
        setOrder({...order,status:status})
        setErrorMessage({
          message:"Status updated successfully.",
          isError:false
        })
        setTimeout(()=>{
          setErrorMessage({
            message:"",
            isError:true
          })
        },3000)
      }
    }catch(error){
      setErrorMessage({
        message:JSON.stringify(error),
        isError:true
      })
      setTimeout(()=>{
        setErrorMessage({
          message:"",
          isError:false
        })
      },3000)
    }
   
    
  }
  return (
    <StyledContainer>
      <StyledHeader>
        <Typography variant="h4">Order Details</Typography>
        <Typography>Order ID: {order.id}</Typography>
        <Typography>Order Date: {new Date(order.orderDate).toLocaleDateString()}</Typography>
        <Typography>Status: {order.status}</Typography>
      </StyledHeader>
      <FormControl style={{width:"200px"}}>
               <InputLabel>
               Status
              </InputLabel>
              <Select
              size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                label="Status"
                onChange={(e)=> onChangeStatus(e.target.value)}
              >
                <MenuItem value={"Pending"}>Pending</MenuItem>
                <MenuItem value={"Processing"}>Processing</MenuItem>
                <MenuItem value={"Shipped"}>Shipped</MenuItem>
                <MenuItem value={"Completed"}>Completed</MenuItem>
              </Select>
              <label style={{color:errorMessage.isError ? "red":"green"}}>{errorMessage.message}</label>
              <br/>
            </FormControl>
           
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6">Total Quantity: {order.totalQty} </Typography>
        </Grid>
        <Grid item xs={6} textAlign="right">
          <Typography variant="h6">Total Price: ${order.totalPrice}</Typography>
        </Grid>
      </Grid>

      <StyledTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell>Main Attribute</TableCell>
              <TableCell>Attribute</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Without GST Price</TableCell>
              <TableCell>Gst</TableCell>
              <TableCell>With GST Price</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderProducts.map((product: any) => (
              <TableRow key={product.id}>
                <TableCell>{product.name}</TableCell>
                <TableCell>{product.attribute.mainAttributeName}</TableCell>
                <TableCell> {product.attribute.attributeName}</TableCell>
                <TableCell>{product.orderQty}{product.attribute.unit}</TableCell>
                <TableCell> {product.attribute.price_per_unit}</TableCell>
                <TableCell>{product.attribute.gst_percentage}%</TableCell>
                <TableCell>{product.attribute.price}</TableCell>
                <TableCell>{(product.attribute.price * product.orderQty).toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>

      <Grid container justifyContent="center">
      <StyledButton variant="contained" onClick={()=>{
        setOpenInvoiceDailog(true)
      }}>Print Invoice</StyledButton>
            <DialogInvoice
            data={orderProducts} open={openInvoiceDailog} setOpen={setOpenInvoiceDailog}
            setOrderProducts={setOrderProducts} order={order}
            />
      </Grid>
    </StyledContainer>
  );
};

export default OrderDetailPage;
