import React,{useEffect} from 'react';
import DashboardUI from "../../components/admin/dashboard_with_gst/components/Dashboard";
import PersistentDrawerLeft from "../../components/reusable/admin/Main";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const navigate = useNavigate();
  const token:string = localStorage.getItem("token") ? ""+localStorage.getItem("token")+"" : "";
  const decoded:{id: number; role: string;} = jwtDecode(token);
  useEffect(() => {
    if (token) {
      const decoded: { id: number; role: string } = jwtDecode(token);
      if (decoded.role !== "ADMIN") {
        navigate('/admin/order/create');
      }
    }
  }, [token, navigate]);
  return (
    <div>
      <PersistentDrawerLeft>
     {
      decoded.role === "ADMIN" && <DashboardUI/>
     } 
      </PersistentDrawerLeft>
    </div>
  );
};

export default Dashboard;