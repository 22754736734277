import React,{useState,useEffect} from "react";
import { Grid, Card,Typography } from "@mui/material";
import { Container } from "../styles/Dashboard.style";
import {getProductCount,getOrderCount,getTotalCompletedOrderAmount,getOutOfStockCount,
  getOrdersPerDay, getTotalPricePerDay
}  from "../services/dashboard";
import OrderPerDayUI from "./OrderPerDay";
import TotalPricePerDay from "./TotalPricePerDay";
const Dashboard: React.FC = () => {
  const [ productCount, setProductCount ] = useState(0);
  const [ orderCount, setOrderCount ] = useState(0);
  const [ totalCompletedOrderAmount, setTotalCompletedOrderAmount ] = useState(0);
  const [ outOfStockCount, setOutOfStockCount ] = useState(0);
  const [ ordersPerDay, setOrdersPerDay] = useState([]);
  const [ totalPricePerDay, setTotalPricePerDay] = useState([]);

  useEffect(()=>{
    fetchProductCount();
    fetchOrderCount();
    fetchTotalCompletedOrderAmount();
    fetchOutOfStockCount();
    fetchOrdersPerDay();
    fetchTotalPricePerDay();
  },[])
  const fetchProductCount = async() => {
        try{
          const response:any = await getProductCount();
          setProductCount(response.data?.totalCount || 0)
        }catch(error){

        }
  }
  const fetchOrderCount = async () => {
    try{
      const response:any = await getOrderCount();
      setOrderCount(response.data?.totalCount || 0)
    }catch(error){

    }
  }
  const fetchTotalCompletedOrderAmount = async () => {
    try{
      const response:any = await getTotalCompletedOrderAmount();
      setTotalCompletedOrderAmount(response.data?.totalCompletedAmount || 0)
    }catch(error){
    }
  }
  const fetchOutOfStockCount = async () => {
    try{
      const response:any = await getOutOfStockCount();
      setOutOfStockCount(response.data?.outOfStockCount || 0)
    }catch(error){
     }
  }
  const fetchOrdersPerDay = async () => {
    try{
      const response:any = await getOrdersPerDay();
      setOrdersPerDay(response.data?.ordersPerDay)
    }catch(error){
     }
  }
  const fetchTotalPricePerDay = async () => {
    try{
      const response:any = await getTotalPricePerDay();
      setTotalPricePerDay(response.data?.totalPricePerDay)
    }catch(error){
     }
  }
 return (
    <Container> 
      <h4>Dashboard W</h4>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={3}>
            <Card sx={{ width:"100%" , height:"100px"  }}>
            <Typography variant="h4" style={{marginTop:"20px",textAlign:"center"}}>
            {productCount}
        </Typography>
        <Typography variant="body2" style={{marginTop:"10px",marginBottom:"10px",textAlign:"center"}}>
            Products
        </Typography>
               
            </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
            <Card sx={{ width:"100%" , height:"100px" }}>
            <Typography variant="h4" style={{marginTop:"20px",textAlign:"center"}}>
            {orderCount}
        </Typography>
        <Typography variant="body2" style={{marginTop:"10px",marginBottom:"10px",textAlign:"center"}}>
            Order Count
        </Typography>
            </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
           <Card sx={{ width:"100%", height:"100px" }}>
           <Typography variant="h4" style={{marginTop:"20px",textAlign:"center"}}>
            {totalCompletedOrderAmount}
        </Typography>
        <Typography variant="body2" style={{marginTop:"10px",marginBottom:"10px",textAlign:"center"}}>
        Total Amount
        </Typography>
           </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
           <Card sx={{ width:"100%" , height:"100px" }}>
           <Typography variant="h4" style={{marginTop:"20px",textAlign:"center"}}>
            {outOfStockCount}
        </Typography>
        <Typography variant="body2" style={{marginTop:"10px",marginBottom:"10px",textAlign:"center"}}>
        Out of Stock Products
        </Typography>
           </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h5">Orders counts of current month</Typography>
            <OrderPerDayUI data={ordersPerDay} />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h5">Orders amount of current month</Typography>
            <TotalPricePerDay data={totalPricePerDay} />
        </Grid>
      </Grid>
    </Container>
 ) 
}
export default Dashboard;

