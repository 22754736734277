import React, { useState } from "react";
import { Typography } from "@mui/material";
import {
  Container,
  FormWrapper,
  StyledTextField,
  StyledButton,
} from "../styles/Login.style";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import {login} from "../services/login";
const Login: React.FC = () => {
  const [mobileNo, setMobileNo] = useState<number>();
  const [password, setPassword] = useState<string>("");
  const [errorMessage,setErrorMessage] = useState<string>("");
  const navigate = useNavigate();
  const handleLogin = async () => {
    if(mobileNo && password){
      setErrorMessage("");
      try {
        const response:any = await login(mobileNo,password)
         if(response.data.token){
          const token = response.data.token;
          const decoded:{id: number; role: string;} = jwtDecode(token);
          if(decoded.role === 'ADMIN' || decoded.role === "EMPLOYEE"){
            window.location.href = "https://onkartraders.devminds.co.in/"
          }else{
            navigate('/home');
          }
          localStorage.setItem("token", token);

         }else{
          const message = response.data.result;
          setErrorMessage(message);
         }
       
        // Redirect to another page or perform further actions here
      } catch (error) {
        setErrorMessage("Login failed. Please check your credentials.");
      }
    }else{
      setErrorMessage("Please enter Mobile No or Password! ")
    }
   
  };

  return (
    <Container>
      <FormWrapper>
        <Typography variant="h4" gutterBottom color="white">
          Login
        </Typography>
        <StyledTextField
          label="Mobile No"
          variant="outlined"
          value={mobileNo}
          onChange={(e) => setMobileNo(Number(e.target.value) as number)}
          InputLabelProps={{
            style: { color: "#ffffff" },
          }}
        />
        <StyledTextField
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputLabelProps={{
            style: { color: "#ffffff" },
          }}
        />
        <label style={{color:"red",textShadow: "1px 0px 7px #ffffff"}}>{errorMessage}</label><br/>
        <StyledButton variant="contained" onClick={handleLogin}>
          Sign In
        </StyledButton>
      </FormWrapper>
    </Container>
  );
};

export default Login;
