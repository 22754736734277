import React, { useState } from 'react';
import { Switch, FormControlLabel } from '@mui/material';
import {calculateGStPrice} from "../constants/index";
const GstSwitch = ({withGst, setWithGst,orderProducts,setOrderProducts,gstRates,units}:any) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWithGst(event.target.checked ? 1 : 2); // Set to 1 if true, 2 if false
    if(event.target.checked){
     //with GST
        
        let updatedData = orderProducts
        updatedData.map((e:any)=>{
            let gst_percentage =  gstRates && gstRates.length > 0 ? gstRates[1].rate :5;
            gst_percentage = e.attribute.product_init_gst_per ? e.attribute.product_init_gst_per : gst_percentage
            let newObj = calculateGStPrice(e.orderQty,gst_percentage,e.attribute,e.attribute.unit,units)
            e.attribute = newObj;
            return e
        })
        setOrderProducts([...updatedData])
    }else{
     //without GST
        let gst_percentage = 0;

        let updatedData = orderProducts
        updatedData.map((e:any)=>{
            let newObj = calculateGStPrice(e.orderQty,gst_percentage,e.attribute,e.attribute.unit,units)
            e.attribute = newObj;
            return e
        })
        setOrderProducts([...updatedData])

    }
  };
  
  return (
    <FormControlLabel
      control={
        <Switch
          checked={withGst === 1}
          onChange={handleChange}
          color="primary"
        />
      }
      label={` ${withGst === 1 ? 'With GST' : 'Without GST'}`}
    />
  );
};

export default GstSwitch;
