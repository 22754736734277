import React from 'react';
import ProductUI from "../../components/admin/product/components/Product";
import PersistentDrawerLeft from "../../components/reusable/admin/Main";
const ProductEdit = () => {
  return (
    <div>
   <PersistentDrawerLeft>
      <ProductUI/>
</PersistentDrawerLeft>
    </div>
  );
};

export default ProductEdit;