import React, { useState,useEffect } from 'react';
import { TextField, Button, Grid, Typography,Box } from '@mui/material';
import { styled } from '@mui/system';
import { Container,AttributeContainer,TextFieldCustom,FormDiv,ButtonCust } from "../styles/Product.style";
import DeleteIcon from '@mui/icons-material/Delete';
import {BUTTON_STYLE, HEADING_COLOR,CANCEL_BUTTON_STYLE} from "../../../../constants/style"
import {STORE_ID} from "../../../../constants/index";
import {create_user,user_list} from '../services/users';
import {type Category, type MainAttributes, type Attributes} from "../interface/index"
import { useNavigate,useParams } from 'react-router-dom';
import ImageIcon from '@mui/icons-material/Image';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {API_BASE_URL,IMG_BASE_URL,defaultMainAttribute,defaultAttribute,defaultCategory,defaultSubCategory} from "../../../../constants/index";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


// Styled Button
const SubmitButton = styled(Button)({
  marginTop: '20px',
  backgroundColor: '#1976d2',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#1565c0',
  },
});



const ProductForm: React.FC = () => {
  const {productId}= useParams();
  const navigate = useNavigate();
  const [errorMessage,setErrorMessage] = useState("");
  const [product, setProduct] = useState<any>({
    name: '',
    categoryId: !productId ? defaultCategory : "",
    subCategoryId: !productId ? defaultSubCategory : "",
    description: '',
    storeId: '',
    attributes: [
      {
        mainAttributesId: !productId ? defaultMainAttribute : "",
        attributeId: !productId ? defaultAttribute : "",
        mrpPrice: '0',
        discount: '0',
        price: '0',
        base_unit:"no",
        stockQty: '1',
        attributesList: !productId ? [{
           "id":defaultAttribute,
            name:"None"
        }] : [],
        rackSectionList: [],
        racksId:"",
        rackSectionsId:"",
        images:[]
      },
    ],
    
  });
  const [categories,setCategories] = useState([]);
  const [mainAttributes,setMainAttributes] = useState([]);
  const [baseUnits,setBaseUnits] = useState([]);
  const [racks,setRacks] = useState([]);
  const [rackSections,setRackSections] = useState([]);
  const [subCategories,setSubCategories] = useState(
    !productId ? [{
    "id":defaultSubCategory,
    name:"None"
  }] : []);
  const [allAttributes,setAllAttributes] = useState([]);
 
  return (
    <Container>
      <FormDiv>
      <Box sx={{display:"flex",justifyContent: "space-between"}}>
      <Typography variant="h6">Create User</Typography>
      </Box><br/>
       
      <Box sx={{textAlign:"center",marginBottom:"10px"}}>
        <label style={{color:"red"}}>{errorMessage}</label>
      </Box>

        <ButtonCust onClick={()=>{}} sx={BUTTON_STYLE}>
          Submit
        </ButtonCust> &nbsp;
        <ButtonCust  sx={CANCEL_BUTTON_STYLE} onClick={()=>{
           navigate('/admin/product_list');
        }}>
          Cancel
        </ButtonCust>
      </FormDiv>
    </Container>
  );
};
export default ProductForm;
