import React,{useEffect} from 'react';
import { Typography, Grid, Paper } from '@mui/material';
import { styled } from '@mui/system';

const ProductContainer = styled("div")`
  padding: 16px;
  @media (max-width: 600px) {
    padding: 8px;
  };
 
`;

const ProductTitle = styled(Typography)`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const AttributeBox = styled(Paper)`
  padding: 16px;
  margin-bottom: 8px;
  @media (max-width: 600px) {
    padding: 8px;
  };
   cursor: pointer;
`;

const StyledTypography = styled(Typography)`
  font-size: 16px;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const ProductDescribe = ({ product,onSelectToOrder,orderProducts }: any) => {
    const onClickProduct = (attr:any) => {
        onSelectToOrder({
            id:product.id,
            name:product.productName,
            attribute:attr
        })
    }

    
  return (
    
        product && product.product_attributes && product.product_attributes.length > 0 &&  (
            <ProductContainer >
            <ProductTitle variant="h5">{product.productName}</ProductTitle>
            <Grid container spacing={2}>
              {product.product_attributes.map((attr: any) => (
                orderProducts && orderProducts.length > 0 ? 
         
                orderProducts.some((item:any) => item.attribute.id === attr.id)  ? null :
                <Grid item xs={12} sm={6} md={2} lg={2} key={`AttributeBox${attr.id}`}>
                <AttributeBox onClick={() => onClickProduct(attr)}>
                  <StyledTypography variant="body1">
                    <strong>{attr.mainAttributeName}</strong>: {attr.attributeName}
                  </StyledTypography>
                  <StyledTypography variant="body1">
                    <strong>Price</strong>: ${attr.price}
                  </StyledTypography>
                  <StyledTypography variant="body1">
                    <strong>Stock Qty</strong>: {attr.stockQty}
                  </StyledTypography>
                </AttributeBox>
              </Grid> 
                :   <Grid item xs={12} sm={6} md={2} lg={2} key={`AttributeBox${attr.id}`}>
                <AttributeBox onClick={() => onClickProduct(attr)}>
                  <StyledTypography variant="body1">
                    <strong>{attr.mainAttributeName}</strong>: {attr.attributeName}
                  </StyledTypography>
                  <StyledTypography variant="body1">
                    <strong>Price</strong>: ${attr.price}
                  </StyledTypography>
                  <StyledTypography variant="body1">
                    <strong>Stock Q ty</strong>: {attr.stockQty}
                  </StyledTypography>
                </AttributeBox>
              </Grid>
              ))}
            </Grid>
          </ProductContainer>
        )
    
  );
};

export default ProductDescribe