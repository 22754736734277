import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from "./login";
import Dashboard from "./admin/dashboard";
import DashboardWithGst from "./admin/dashboard_with_gst";
import Product from './admin/product';
import AdminProductList from "./admin/productList";
import AdminProductEdit from "./admin/productEdit";
import AdminOrderCreate from "./admin/order";
import AdminOrderList from "./admin/orderList";
import AdminOrderEdit from "./admin/orderDetail";
import AdminUserList from "./admin/userList";
import AdminUserAdd from "./admin/addUser";

const AppRoutes = () => {
  const token = localStorage.getItem('token');

  return (
    <Routes>
      <Route path="/" element={token ? <Dashboard /> : <Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/admin" element={token ? <Dashboard /> : <Navigate to="/login" />} />
      <Route path="/admin/dashboard_with_gst" element={token ? <DashboardWithGst /> : <Navigate to="/login" />} />
      <Route path="/admin/product" element={token ? <Product /> : <Navigate to="/login" />} />
      <Route path="/admin/product_list" element={token ? <AdminProductList /> : <Navigate to="/login" />} />
      <Route path="/admin/product_edit/:productId" element={token ? <AdminProductEdit /> : <Navigate to="/login" />} />
      <Route path="/admin/order/create" element={token ? <AdminOrderCreate /> : <Navigate to="/login" />} />
      <Route path="/admin/order_list" element={token ? <AdminOrderList /> : <Navigate to="/login" />} />
      <Route path="/admin/order_edit/:orderId" element={token ? <AdminOrderEdit /> : <Navigate to="/login" />} />
      <Route path="/admin/users_list" element={token ? <AdminUserList /> : <Navigate to="/login" />} />
      <Route path="/admin/users_add" element={token ? <AdminUserAdd /> : <Navigate to="/login" />} />
    </Routes>
  );
};

export default AppRoutes;
