import React from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography } from '@mui/material';
import { styled } from '@mui/system';
import {convertNumberToWords} from "../../constants/index";
import "./Invoice.css";

// Styled component for table cells
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid black',
  padding: theme.spacing(0.5),
  fontSize: '12px', // Apply font size to table cells
}));
const StyledTableCellDummy = styled(TableCell)(({ theme }) => ({
  border: '0px solid black',
  padding: theme.spacing(0.5),
  fontSize: '12px', // Apply font size to table cells
}));
// Styled component for bordered input fields
const BorderedInput = styled(Box)(({ theme }) => ({
  // borderBottom: '1px solid black',
  display: 'inline-block',
  minWidth: '100%',  // Adjust this width as needed
}));

// Styled Box for spacing the sections
const InfoSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),  // Adding vertical space between lines
}));

// Main Invoice component
const InvoiceDetailComp = ({order,data,calculateGrandTotal,calculateGSTTotal}:any) => {
  return (
    <div className="background-container-invoice">
  <div className="overlay-invoice">
    <Box sx={{ fontSize: '12px' }} className="content-invoice"> {/* Set global font size for entire component */}
      <TableContainer  sx={{ width: '100%', padding: '10px' }}>
        <Table>
          {/* Client Information and P.O. Details */}
          <TableBody>
            <TableRow>
              <StyledTableCell colSpan={4}>
                <InfoSection>
                  <Typography sx={{ fontSize: '12px' }}>To:</Typography>
                  <Typography sx={{ fontSize: '12px' }}>
                    Client Name:  <b>{order.fullName}</b>
                  </Typography>
                  <Typography sx={{ fontSize: '12px' }}>
                  {order.addressInfo}
                  </Typography>
                  <Typography sx={{ fontSize: '12px' }}>
                    GSTIN: <b>{order.GSTnumber}</b>
                  </Typography>
                  <Typography sx={{ fontSize: '12px' }} style={{borderBottom:"1px solid black"}}>
                    State Code: 
                  </Typography>
                  <Typography sx={{ fontSize: '12px' }}>
                    Tax is payable on Reverse Charge: Yes / No
                  </Typography>
                  <Typography sx={{ fontSize: '12px' }}>
                    Terms of Payment: 
                  </Typography>
                </InfoSection>
              </StyledTableCell>
              <StyledTableCell colSpan={2}>
                <InfoSection>
                  <Typography sx={{ fontSize: '12px' }}>
                    P.O. No: -<BorderedInput /> 
                  </Typography>
                  <Typography sx={{ fontSize: '12px' }}>
                    TPT Throu: - <BorderedInput /> 
                  </Typography>
                  <Typography sx={{ fontSize: '12px' }}>
                    Vehicle No: - <BorderedInput /> 
                  </Typography>
                  <Typography sx={{ fontSize: '12px' }}>
                    Dt. & Time of Supply: - <BorderedInput />
                  </Typography>
                  <Typography sx={{ fontSize: '12px' }}>
                    Place of Supply: - <BorderedInput /> 
                  </Typography>
                </InfoSection>
              </StyledTableCell>
            </TableRow>

            {/* Goods/Services Details Table Header */}
            <TableRow>
              <StyledTableCell>Sr.No</StyledTableCell>
              <StyledTableCell>HSN/SAC</StyledTableCell>
              <StyledTableCell>Description of Goods / Services</StyledTableCell>
              <StyledTableCell>Quantity</StyledTableCell>
              <StyledTableCell>Unit Rate</StyledTableCell>
              <StyledTableCell>Amount</StyledTableCell>
            </TableRow>

            {/* Goods/Services Empty Rows for Input */}
            {data.map((e:any,index:any) => (
              <TableRow key={"Invoice"+index}>
                <StyledTableCellDummy style={{borderLeft: "1px solid black"}}> {index+1}</StyledTableCellDummy>
                <StyledTableCellDummy>- </StyledTableCellDummy>
                <StyledTableCellDummy> {e.name}</StyledTableCellDummy>
                <StyledTableCellDummy>{e.orderQty} {e.attribute.unit} </StyledTableCellDummy>
                <StyledTableCellDummy> {(e.attribute.price - e.attribute.gst_per_price).toFixed(2)}</StyledTableCellDummy>
                <StyledTableCellDummy style={{borderRight: "1px solid black"}}>{e.attribute.totalPrice ? (e.attribute.totalPrice - e.attribute.gst_price_total).toFixed(2) 
                : ((e.attribute.price * e.orderQty) - e.attribute.gst_price_total).toFixed(2)  } </StyledTableCellDummy>
              </TableRow>
            ))}
            {
             data &&  data.length > 4  ? 
             Array.from({ length:8 }, (_, i) => i + 1).map((e,i)=>{
              return (
                <TableRow key={"Invoicedummy"+i}>
                <StyledTableCellDummy style={{borderLeft: "1px solid black"}}> </StyledTableCellDummy>
                <StyledTableCellDummy> </StyledTableCellDummy>
                <StyledTableCellDummy></StyledTableCellDummy>
                <StyledTableCellDummy> </StyledTableCellDummy>
                <StyledTableCellDummy></StyledTableCellDummy>
                <StyledTableCellDummy style={{borderRight: "1px solid black"}}> </StyledTableCellDummy>
              </TableRow>
              )
            })
             : (
              Array.from({ length:20 }, (_, i) => i + 1).map((e,i)=>{
                return (
                  <TableRow key={"Invoicedummy"+i}>
                  <StyledTableCellDummy style={{borderLeft: "1px solid black"}}> </StyledTableCellDummy>
                  <StyledTableCellDummy> </StyledTableCellDummy>
                  <StyledTableCellDummy></StyledTableCellDummy>
                  <StyledTableCellDummy> </StyledTableCellDummy>
                  <StyledTableCellDummy></StyledTableCellDummy>
                  <StyledTableCellDummy style={{borderRight: "1px solid black"}}> </StyledTableCellDummy>
                </TableRow>
                )
              })
             )
            }
            <TableRow>
            <StyledTableCell colSpan={2} >Taxable amount</StyledTableCell>
            <StyledTableCell colSpan={1} >CGST </StyledTableCell>
            <StyledTableCell colSpan={1} >SGST</StyledTableCell>
            <StyledTableCell colSpan={1} style={{    borderBottom: "1px solid #ffffff"}} >Total</StyledTableCell>
            <StyledTableCell colSpan={1} style={{    borderBottom: "1px solid #ffffff"}}>
              {(calculateGrandTotal().toFixed(2) - calculateGSTTotal().toFixed(4)).toFixed(2)}</StyledTableCell>
            </TableRow>
            <TableRow>
            <StyledTableCell colSpan={2} >{(calculateGrandTotal().toFixed(4) - calculateGSTTotal().toFixed(4)).toFixed(2)}</StyledTableCell>
            <StyledTableCell colSpan={1} >{(calculateGSTTotal().toFixed(4) / 2 ).toFixed(2)}</StyledTableCell>
            <StyledTableCell colSpan={1} >{(calculateGSTTotal().toFixed(4) / 2 ).toFixed(2)}</StyledTableCell>
            <StyledTableCell colSpan={1}  style={{    borderBottom: "1px solid #ffffff", borderTop: "1px solid #ffffff"}}>Freight Charges</StyledTableCell>
            <StyledTableCell colSpan={1} style={{    borderBottom: "1px solid #ffffff", borderTop: "1px solid #ffffff"}}>0</StyledTableCell>
            </TableRow>
            <TableRow>
            <StyledTableCell colSpan={2} rowSpan={2} style={{    borderRight: "1px solid #ffffff"}}>Grand Total Rs. - </StyledTableCell>
            <StyledTableCell colSpan={2} rowSpan={2}>
              {convertNumberToWords(calculateGrandTotal().toFixed(2))}.
              </StyledTableCell>
            <StyledTableCell colSpan={1} style={{    borderBottom: "1px solid #ffffff", borderTop: "1px solid #ffffff"}}>P & F Charges</StyledTableCell>
            <StyledTableCell colSpan={1} style={{    borderBottom: "1px solid #ffffff", borderTop: "1px solid #ffffff"}}>0</StyledTableCell>
            </TableRow>
            <TableRow>
            <StyledTableCell colSpan={1} style={{    borderBottom: "1px solid #ffffff", borderTop: "1px solid #ffffff"}}>Insurance Charges</StyledTableCell>
            <StyledTableCell colSpan={1} style={{    borderBottom: "1px solid #ffffff", borderTop: "1px solid #ffffff"}}>0</StyledTableCell>
            </TableRow>
            <TableRow>
            <StyledTableCell colSpan={4} style={{     borderBottom: "1px solid #ffffff"}} > Terms & Conditions </StyledTableCell>
            <StyledTableCell colSpan={1} style={{     borderTop: "1px solid #ffffff"}}>Other Charges</StyledTableCell>
            <StyledTableCell colSpan={1} style={{     borderTop: "1px solid #ffffff"}} >0</StyledTableCell>
            </TableRow>

            <TableRow>
            <StyledTableCell colSpan={4} style={{    borderBottom: "1px solid #ffffff", borderTop: "1px solid #ffffff"}} > As Applicable </StyledTableCell>
            <StyledTableCell colSpan={1} ><b>Taxable Amount</b></StyledTableCell>
            <StyledTableCell colSpan={1} >{(calculateGrandTotal().toFixed(4) - calculateGSTTotal().toFixed(4)).toFixed(2)}</StyledTableCell>
            </TableRow>

            <TableRow>
            <StyledTableCell colSpan={4} rowSpan={4} > </StyledTableCell>
            <StyledTableCell colSpan={1} style={{    borderBottom: "1px solid #ffffff"}}>CGST @ 9%</StyledTableCell>
            <StyledTableCell colSpan={1} style={{    borderBottom: "1px solid #ffffff"}}>{(calculateGSTTotal().toFixed(2) / 2 ).toFixed(2)}</StyledTableCell>
            </TableRow>
            <TableRow>
           
            <StyledTableCell colSpan={1}   style={{    borderBottom: "1px solid #ffffff", borderTop: "1px solid #ffffff"}}>SGST @ 9%</StyledTableCell>
            <StyledTableCell colSpan={1}  style={{    borderBottom: "1px solid #ffffff", borderTop: "1px solid #ffffff"}}>{(calculateGSTTotal().toFixed(4) / 2 ).toFixed(2)}</StyledTableCell>
            </TableRow>
            <TableRow>
            
            <StyledTableCell colSpan={1}  style={{    borderBottom: "1px solid #ffffff", borderTop: "1px solid #ffffff"}}>ROUND OFF</StyledTableCell>
            <StyledTableCell colSpan={1}  style={{    borderBottom: "1px solid #ffffff", borderTop: "1px solid #ffffff"}}>0.1</StyledTableCell>
            </TableRow>
            <TableRow>
            
            <StyledTableCell colSpan={1} style={{    borderTop: "1px solid #ffffff"}} >IGST @ %</StyledTableCell>
            <StyledTableCell colSpan={1} style={{    borderTop: "1px solid #ffffff"}}>0</StyledTableCell>
            </TableRow>

            <TableRow>
            <StyledTableCell colSpan={4}  >Our Bankers:  </StyledTableCell>
            <StyledTableCell colSpan={1} style={{fontWeight:600}}>Grand Total</StyledTableCell>
            <StyledTableCell colSpan={1} > {calculateGrandTotal().toFixed(2)}</StyledTableCell>
            </TableRow>
            <TableRow>
            <StyledTableCell colSpan={4}  >Ac. Name : ONKAR TRADERS  </StyledTableCell>
            <StyledTableCell colSpan={2} > <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'purple', fontSize: '12px' }}>
                  For ONKAR TRADERS
                </Typography></StyledTableCell>
            </TableRow>

            <TableRow>
            <StyledTableCell colSpan={4}  >Bank Name & Address : HDFC BANK , R. K. Nagar , Kolhapur. </StyledTableCell>
            <StyledTableCell colSpan={2} ></StyledTableCell>
            </TableRow>

            <TableRow>
            <StyledTableCell colSpan={4}  >C/Ac. No. : 50200084715217 IFSC Code : HDFC0006694
            </StyledTableCell>
            <StyledTableCell colSpan={2} ></StyledTableCell>
            </TableRow>
            <TableRow>
            <StyledTableCell colSpan={4}  >Certified That Particulars given above are true & correct.  </StyledTableCell>
            <StyledTableCell colSpan={2} ><Typography variant="body2" sx={{ fontWeight: 'bold', color: 'purple', fontSize: '12px' }}>
            Authorised Signatory
                </Typography></StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
        <label>* Subjected to Kolhapur Juridiction *
        </label>
        <div style={{width:"100%",height:"25px", backgroundColor:"#c4daff"}}></div>
      </TableContainer>
     
    </Box>
    </div>
    </div>
  );
};

export default InvoiceDetailComp;
