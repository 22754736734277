import React from 'react';
import ProductListUI from "../../components/admin/product/components/ProductList";
import PersistentDrawerLeft from "../../components/reusable/admin/Main";
const ProductList = () => {
  return (
    <div>
      <PersistentDrawerLeft>
      <ProductListUI/>
      </PersistentDrawerLeft>
    </div>
  );
};

export default ProductList;