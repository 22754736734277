import axios from "axios";
import {restApi} from "../../../../constants/api";
export const getProductCount = async () => {
        const response = await restApi("dashboard/product-count?isWithGst=true","GET",{});
        return response 
};
export const getOrderCount = async () => {
    const response = await restApi("dashboard/order-count?isWithGst=true","GET",{});
    return response 
};
export const getTotalCompletedOrderAmount = async () => {
    const response = await restApi("dashboard/total-completed-order-amount?isWithGst=true","GET",{});
    return response 
};
export const getOutOfStockCount = async () => {
    const response = await restApi("dashboard/out-of-stock-count?isWithGst=true","GET",{});
    return response 
};

export const getOrdersPerDay = async () => {
    const response = await restApi("dashboard/orders-per-day?isWithGst=true","GET",{});
    return response 
};

export const getTotalPricePerDay = async () => {
    const response = await restApi("dashboard/total-price-per-day?isWithGst=true","GET",{});
    return response 
};


