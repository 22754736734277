import axios from "axios";
import {restApi} from "../../../../constants/api";
export const get_categories = async () => {
        const response = await restApi("categories","GET",{});
        return response 
};
export const get_mainAttributes = async () => {
    const response = await restApi("main_attributes","GET",{});
    return response 
};

export const get_sub_categories = async (subCategoryId:number) => {
    const response = await restApi(`sub_categories?subCategoryId=${subCategoryId}`,"GET",{});
    return response 
};
export const get_attributes = async (mainAttributesId:number) => {
    const response = await restApi(`attributes?mainAttributesId=${mainAttributesId}`,"GET",{});
    return response 
};

export const create_product = async (payload:any) => {
    const response = await restApi(`product`,"POST",payload);
    return response 
};

export const product_list = async (page:number, limit:number, 
    stockQtyStart:number | string,stockQtyEnd:number | string,search?:string) => {
    let finalStartStockQty = stockQtyStart != "" && (Number(stockQtyStart) >= 0) ?  stockQtyStart : ""
    let finalEndStockQty = stockQtyEnd != "" && (Number(stockQtyEnd) >= 0) ?  stockQtyEnd : ""
    let finalSearch = search && search !== "" ? search : ""
    const response = await restApi(`product?page=${page}&limit=${limit}&stockQtyStart=${finalStartStockQty}&stockQtyEnd=${finalEndStockQty}&search=${finalSearch}`,"GET");
    return response 
};

export const product_detail = async (productId:number) => {
    const response = await restApi(`product/${productId}`,"GET");
    return response 
};

export const update_product = async (payload:any,productId:number) => {
    const response = await restApi(`product/${productId}`,"PATCH",payload);
    return response 
};

export const get_all_sub_attributes  =  async () => {
    const response = await restApi(`attributes?isAll=true`,"GET");
    return response 
}; 

export const product_list_total = async (search:string = "",
    stockQtyStart:number | string,stockQtyEnd:number | string
) => {
    let finalSearch = search && search !== "" ? search : ""
    let finalStartStockQty = stockQtyStart != "" && (Number(stockQtyStart) >= 0) ?  stockQtyStart : ""
    let finalEndStockQty = stockQtyEnd != "" && (Number(stockQtyEnd) >= 0) ?  stockQtyEnd : ""
    const response = await restApi(`product/total?search=${finalSearch}&stockQtyStart=${finalStartStockQty}&stockQtyEnd=${finalEndStockQty}`,"GET");
    return response 
};



export const get_racks = async () => {
    const response = await restApi("racks","GET",{});
    return response 
};

export const get_base_units = async () => {
    const response = await restApi("units","GET",{});
    return response 
};

export const get_gst_rates = async () =>{
    const response = await restApi(`/gst`,"GET");
    return response 
}