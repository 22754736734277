import React, { useState,useEffect } from 'react';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Box, Grid,IconButton ,Typography  } from '@mui/material';
import { styled } from '@mui/system';
import { Container,AttributeContainer,TextFieldCustom,FormDiv,ButtonCust } from "../styles/Product.style";
import DeleteIcon from '@mui/icons-material/Delete';
import {BUTTON_STYLE, HEADING_COLOR,CANCEL_BUTTON_STYLE} from "../../../../constants/style"
import {STORE_ID} from "../../../../constants/index";
import {get_categories,get_mainAttributes,get_sub_categories,update_product,
  create_product,product_detail,get_all_sub_attributes,get_racks,get_base_units,get_gst_rates} from '../services/product';
import {type Category, type MainAttributes, type Attributes} from "../interface/index"
import { useNavigate,useParams } from 'react-router-dom';
import ImageIcon from '@mui/icons-material/Image';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {API_BASE_URL,IMG_BASE_URL,defaultMainAttribute,defaultAttribute,defaultCategory,defaultSubCategory} from "../../../../constants/index";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const ProductForm: React.FC = () => {
  const {productId}= useParams();
  const navigate = useNavigate();
  const [errorMessage,setErrorMessage] = useState("");
  const [product, setProduct] = useState<any>({
    name: '',
    categoryId: !productId ? defaultCategory : "",
    subCategoryId: !productId ? defaultSubCategory : "",
    description: '',
    storeId: '',
    attributes: [
      {
        mainAttributesId: !productId ? defaultMainAttribute : "",
        attributeId: !productId ? defaultAttribute : "",
        mrpPrice: '0',
        discount: '0',
        price: '0',
        base_unit:"no",
        gst_percentage:0,
        stockQty: '1',
        attributesList: !productId ? [{
           "id":defaultAttribute,
            name:"None"
        }] : [],
        rackSectionList: [],
        racksId:"",
        rackSectionsId:"",
        images:[]
      },
    ],
    
  });
  const [categories,setCategories] = useState([]);
  const [mainAttributes,setMainAttributes] = useState([]);
  const [baseUnits,setBaseUnits] = useState([]);
  const [racks,setRacks] = useState([]);
  const [rackSections,setRackSections] = useState([]);
  const [gstRates,setGstRates] = useState([]);
  const [subCategories,setSubCategories] = useState(
    !productId ? [{
    "id":defaultSubCategory,
    name:"None"
  }] : []);
  const [allAttributes,setAllAttributes] = useState([]);
  useEffect(()=>{
    fetch_categories();
    fetch_mainAttributes();
    fetch_baseUnits();
    fetchGstRates();
    if(!productId){
      fetch_racks();
      fetch_all_sub_attributes();
    }
  },[]);
  useEffect(()=>{
  if(productId && Number(productId) > 0){
    fetch_product_detail(Number(productId))
  }
  },[productId]);
  const fetchGstRates = async () => {
    try{
       const response:any = await get_gst_rates();
       setGstRates(response.data?.data)
    }catch(error){
      
    }
}
  const fetch_product_detail =  async (productId:number) => {
    try{
      const allSubAttributes = await fetch_all_sub_attributes();
      const racks = await fetch_racks();
      const response:any = await product_detail(productId);
      let productData = response.data;
      setProduct(response.data)
      fetch_sub_categories(response.data?.categoryId)
      if(productData && productData.attributes && allSubAttributes && allSubAttributes.length > 0){
        await Promise.all(productData.attributes.map(async(attribute: any, i: number) => {
          const attributesList = allSubAttributes.filter((e:Attributes)=> e.mainAttributesId == attribute.mainAttributesId);
          productData.attributes[i].attributesList = attributesList; 
          const rackSectionList = racks && racks.length > 0 && attribute.racksId ? 
                                     racks.filter((e:{id:number,rack_id:number,section_name:string})=>
                                     e.rack_id == attribute.racksId) : [];
          productData.attributes[i].rackSectionList = [...rackSectionList];
          productData.attributes[i].images = [];
          if(attribute.image1){
            productData.attributes[i].images.push(attribute.image1)
          }
          if(attribute.image2){
            productData.attributes[i].images.push(attribute.image2)
          }
          if(attribute.image3){
            productData.attributes[i].images.push(attribute.image3)
          }
          if(attribute.image4){
            productData.attributes[i].images.push(attribute.image4)
          }
          
        }));
      }
      
    }catch(error){
    }
  }
  const fetch_categories = async() => {
    try{
      const response:any = await get_categories();
      setCategories(response.data.data)
    }catch(error){
    }
  }
  const fetch_mainAttributes= async() => {
    try{
      const response:any = await get_mainAttributes();
      setMainAttributes(response.data.data)
    }catch(error){
    }
  }
  const fetch_baseUnits= async() => {
    try{
      const response:any = await get_base_units();
      setBaseUnits(response.data.data)
    }catch(error){
    }
  }
  const fetch_racks = async() => {
    try{
      const response:any = await get_racks();
      setRacks(response.data.racks)
      setRackSections(response.data.rack_sections)
      return response.data?.rack_sections;
    }catch(error){
      return [];
    }
  }
  const fetch_all_sub_attributes = async() => {
    try{
      const response:any = await get_all_sub_attributes();
      setAllAttributes(response.data?.data)
      return response.data?.data;
    }catch(error){
      return [];
    }
  }
  
  const fetch_sub_categories = async(subCategoryId:number) => {
    try{
      const response:any = await get_sub_categories(subCategoryId);
      setSubCategories(response.data.data)
    }catch(error){
    }
  }
  const fetch_Attributes= async(mainAttributesId:number) => {
      const data = allAttributes.filter((e:Attributes)=> e.mainAttributesId == mainAttributesId);
      return data;
  }
  const fetch_RackSections = async(racksId:number) => {
    const data = rackSections.filter((e:{id:number,rack_id:number,section_name:string})=> e.rack_id == racksId);
      return data;
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
   
    if(name === "categoryId" && value && Number(value) > 0){
     
      setProduct((prevState:any) => ({
        ...prevState,
        [name]: value,
        "subCategoryId":''
      }));
      fetch_sub_categories(Number(value) )
    }else{
      setProduct((prevState:any) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleAttributeChange = async (index: number, e:any) => {
    const { name, value } = e.target;
    const updatedAttributes = await Promise.all(product.attributes.map(async(attribute:any, i:number) => {
      if (i === index) {
        let updatedAttribute = { ...attribute, [name as string]: value };
         if(name === "mainAttributesId" && value && Number(value)>0){
           updatedAttribute = { ...updatedAttribute, "attributeId": "" };          
           const attributesList = await fetch_Attributes(value);
           updatedAttribute = { ...updatedAttribute, "attributesList": attributesList };    
         }

         if(name === "racksId" && value && Number(value)>0){
          updatedAttribute = { ...updatedAttribute, "rackSectionsId": "" };          
          const rackSectionList = await fetch_RackSections(value);
          updatedAttribute = { ...updatedAttribute, "rackSectionList": rackSectionList };            }
        
          if (name === 'mrpPrice' || name === 'discount') {
            const mrpPrice = parseFloat(updatedAttribute.mrpPrice) || 0;
            const discount = parseFloat(updatedAttribute.discount) || 0;
            updatedAttribute.price = (mrpPrice - (mrpPrice * discount) / 100).toFixed(2);
          }
         
        

        return updatedAttribute;
      }
      return attribute;
    }));

    setProduct({ ...product, attributes: updatedAttributes });
  };

  const addAttribute = () => {
    setProduct((prevState:any) => ({
      ...prevState,
      attributes: [
        ...prevState.attributes,
        {
          mainAttributesId:'0',
          attributeId:  '0',
          mrpPrice: '0',
          discount: '0',
          price: '0',
          stockQty: '1',
          base_unit:"no",
          gst_percentage:0,
          attributesList: [],
          rackSectionList: [],
          images:[],
          racksId:"",
          rackSectionsId:"",
        },
      ],
    }));
  };

  const removeAttribute = (index: number) => {
    setProduct((prevState:any) => ({
      ...prevState,
      attributes: prevState.attributes.filter((_:any, i:any) => i !== index),
    }));
  };

  const fileUploads = async() =>{
    let productData = product;
    if(product.attributes){
      
      await Promise.all(product.attributes.map(async(attr:any,index1:any)=>{
         if(attr.images && attr.images.length > 0){
            await Promise.all(attr.images.map(async(img:any,index:any)=>{
              if(img instanceof File || img instanceof Blob){ 
              let formData = new FormData();
              formData.append('image',img);
              try{
                // const response = await file_Upload(formData);
                const response = await fetch(`${API_BASE_URL}upload`, {
                  method: 'POST',
                  body: formData,
                  headers: {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`, // Add Bearer token here
                  },
                });
                // Check if the response was successful
                if (response.ok) {
                  // Parse the response as JSON
                  const responseData = await response.json();
                  productData.attributes[index1].images[index] = responseData.image;
                } else {
                  // Handle errors if the response was not successful
                  console.error("Error:", response.statusText);
                }
              } catch (error) {
                // Handle any errors that occurred during the fetch
                console.error("Fetch error:", error);
              }
             
            }
            }))
         }
      }))
     
    }
    return productData;
  }
  const handleSubmit =async () => {
    const { name, categoryId, subCategoryId, attributes } = product;

    if (!name || !categoryId || !subCategoryId) {
      setErrorMessage("Please fill out all fields.")
      return;
    }

    for (const attribute of attributes) {
      if (!(attribute.mainAttributesId > 0) || !(attribute.attributeId>0) || !(attribute.mrpPrice>0) || !(attribute.price>0)
         || !(attribute.stockQty == 0 || Number(attribute.stockQty) >= 0)
         || !(attribute.discount == 0 || Number(attribute.discount) >= 0)) {
          setErrorMessage("Please fill out all attribute fields.")
        return;
      }
    }
    const seenAttributeIds = new Set();

    for (const attribute of attributes) {
        // Check for duplicate attributeId
        if (seenAttributeIds.has(attribute.attributeId)) {
            setErrorMessage(`Please remove duplicate attributes`);
            return;
        }
        // Add the attributeId to the set for future duplicate checking
        seenAttributeIds.add(attribute.attributeId);
    }
    setErrorMessage("");
    // Submit logic
  
    try{
      product.storeId = STORE_ID;
      if(productId && Number(productId) > 0){
        let productData = await fileUploads();
        productData.attributes.forEach((attr:any) => {
            delete attr.attributesList;
            delete attr.rackSectionList;
        });
        await update_product(productData,Number(productId))
        navigate("/admin/product_list")
      }else{
        let productData = await fileUploads();
        productData.attributes.forEach((attr:any) => {
            delete attr.attributesList;
            delete attr.rackSectionList;
        });
        await create_product(productData)
        navigate("/admin/product_list")
      }
     
      
    }catch(error){
      
    }
   
  };
  const deleteImage = (index:number,imageIndex:number) => {
    const productData = product;
    productData.attributes[index].images.splice(imageIndex, 1);
    setProduct({...productData});
  }
  return (
    <Container>
      <FormDiv>
      <Box sx={{display:"flex",justifyContent: "space-between"}}>
      <Typography variant="h6">Create Product</Typography>
      </Box><br/>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <TextFieldCustom
              label={
                <>
                  Product Name<span className="MuiInputLabel-asterisk">*</span>
                </>
              }
              name="name"
              value={product.name}
              onChange={handleInputChange}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>
                Category <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <Select
                name="categoryId"
                label="Category *"
                value={product.categoryId}
                onChange={handleInputChange as any}
                fullWidth
              >{
                categories && categories.length > 0 && (
                  categories.map((e:Category,index:number)=>{
                    return (
                      <MenuItem value={e.id} key={"category"+index}>{e.name}</MenuItem>
                    )
                  })
                )
              }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>
                Sub-Category <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <Select
                name="subCategoryId"
                label="Sub-Category *"
                value={product.subCategoryId}
                onChange={handleInputChange as any}
                fullWidth
              >
                {
                  subCategories && subCategories.length > 0 && (
                    subCategories.map((e:Category,index:number)=>{
                      return (
                        <MenuItem value={e.id} key={"subCategories"+index}>{e.name}</MenuItem>
                      )
                    })
                  )
                }
                
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <TextField
          label="Description"
          name="description"
          value={product.description}
          onChange={handleInputChange}
          multiline
          rows={4}
          fullWidth
          sx={{ marginTop: 2 }}
          size="small"
        />

        {product.attributes.map((attribute:any, index:number) => (
          <AttributeContainer key={index}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={2}>
                <FormControl fullWidth size="small">
                  <InputLabel>
                    Main Attribute <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <Select
                    name="mainAttributesId"
                    label="Main Attribute *"
                    value={attribute.mainAttributesId}
                    onChange={e => handleAttributeChange(index, e)}
                    fullWidth
                  >
                    {
                      mainAttributes && mainAttributes.length > 0 && (
                        mainAttributes.map((e:MainAttributes,index:number)=>{
                          return (
                            <MenuItem value={e.id} key={"mainAttributes"+index}>{e.name}</MenuItem>
                          )
                        })
                      )
                    }
                   
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6} md={2}>
                <FormControl fullWidth size="small">
                  <InputLabel>
                    Attribute <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <Select
                    name="attributeId"
                    label="Attribute *"
                    value={attribute.attributeId}
                    onChange={e => handleAttributeChange(index, e)}
                    fullWidth
                  >
                    
                    {
                      attribute.attributesList && attribute.attributesList.length > 0 && (
                        attribute.attributesList.map((e:MainAttributes,index:number)=>{
                          return (
                            <MenuItem value={e.id} key={"attributes"+index}>{e.name}</MenuItem>
                          )
                        })
                      )
                    }
                  
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6} md={2}>
                <FormControl fullWidth size="small">
                  <InputLabel>
                    Base Unit <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <Select
                    name="base_unit"
                    label="Base Unit *"
                    value={attribute.base_unit}
                    onChange={e => handleAttributeChange(index, e)}
                    fullWidth
                  >
                    {
                      baseUnits && baseUnits.length > 0 && (
                        baseUnits.map((e:{
                          base_unit:string
                        },index:number)=>{
                          return (
                            <MenuItem value={e.base_unit} key={"base_unit"+index}>{e.base_unit}</MenuItem>
                          )
                        })
                      )
                    }
                   
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6} md={2}>
                <TextFieldCustom
                  label={
                    <>
                      MRP Price per unit<span className="MuiInputLabel-asterisk">*</span>
                    </>
                  }
                  name="mrpPrice"
                  value={attribute.mrpPrice}
                  onChange={e => handleAttributeChange(index, e)}
                  fullWidth
                  size="small"
                  type="number"
                />
              </Grid>
              <Grid item xs={6} sm={6} md={1}>
                <TextFieldCustom
                  label={
                    <>
                      Discount<span className="MuiInputLabel-asterisk">*</span>
                    </>
                  }
                  name="discount"
                  value={attribute.discount}
                  onChange={e => handleAttributeChange(index, e)}
                  fullWidth
                  size="small"
                  type="number"
                />
              </Grid>
              <Grid item xs={5} sm={5} md={2}>
                <TextFieldCustom
                  label={
                    <>
                      Price per unit<span className="MuiInputLabel-asterisk">*</span>
                    </>
                  }
                  name="price"
                  value={attribute.price}
                  onChange={e => handleAttributeChange(index, e)}
                  fullWidth
                  size="small"
                  type="number"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={5} sm={5} md={1}>
                <TextFieldCustom
                  label={
                    <>
                      Stock<span className="MuiInputLabel-asterisk">*</span>
                    </>
                  }
                  name="stockQty"
                  value={attribute.stockQty}
                  onChange={e => handleAttributeChange(index, e)}
                  fullWidth
                  size="small"
                  type="number"
                />
              </Grid>
              <Grid item xs={6} sm={6} md={2}>
                <FormControl fullWidth size="small">
                  <InputLabel>
                    Rack
                  </InputLabel>
                  <Select
                    name="racksId"
                    label="Rack"
                    value={attribute.racksId}
                    onChange={e => handleAttributeChange(index, e)}
                    fullWidth
                  >
                    {
                      racks && racks.length > 0 && (
                        racks.map((e:{id:number,rack_name:string},indexR:number)=>{
                          return (
                            <MenuItem value={e.id} key={index+"racks"+indexR}>{e.rack_name}</MenuItem>
                          )
                        })
                      )
                    }
                   
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6} md={2}>
                <FormControl fullWidth size="small">
                  <InputLabel>
                  Rack section
                  </InputLabel>
                  <Select
                    name="rackSectionsId"
                    label="Rack section"
                    value={attribute.rackSectionsId}
                    onChange={e => handleAttributeChange(index, e)}
                    fullWidth
                  >
                    
                    {
                      attribute.rackSectionList && attribute.rackSectionList.length > 0 && (
                        attribute.rackSectionList.map((e:{id:number,section_name:string},index:number)=>{
                          return (
                            <MenuItem value={e.id} key={"rackSectionList"+index}>{e.section_name}</MenuItem>
                          )
                        })
                      )
                    }
                  
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6} md={2}>
                <FormControl fullWidth size="small">
                  <InputLabel>
                  GST %
                  </InputLabel>
                  <Select
                    name="gst_percentage"
                    label="GST %"
                    value={attribute.gst_percentage}
                    onChange={e => handleAttributeChange(index, e)}
                    fullWidth
                  >
                    
                    {
                      gstRates && gstRates.length > 0 && (
                        gstRates.map((e:{id:number,rate:string},indexG:number)=>{
                          return (
                            <MenuItem value={e.rate} key={index+"gst"+indexG}>{e.rate}%</MenuItem>
                          )
                        })
                      )
                    }
                  
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1} sm={1} md={2}>
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                  <Button onClick={() => removeAttribute(index)} sx={{ minWidth: "40px"}}>
                    <DeleteIcon />
                  </Button>
                  {/* <Button onClick={() => {}} sx={{ minWidth: "40px"}}>
                    <ImageIcon />
                  </Button> */}

                  <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                sx={{ minWidth: "40px"}}>
               <ImageIcon />
                <VisuallyHiddenInput
                  type="file"
                  onChange={(event) => {
                    if(event.target.files && event.target.files[0]){
                      let file = event.target.files[0];
                      let productData =  product;

                      productData.attributes[index].images.push(file);
                      setProduct({...productData})
                    }
                  }}
                  multiple
                />
              </Button>
                </Box>
              </Grid>
              
              <Grid item xs={12} sm={12} md={12}>
                  <Grid container spacing={2}>
                    {
                      
                      attribute.images && attribute.images.length > 0 ? 
                        attribute.images.map((e:any,imageIndex:any)=>{
                          return ( 
                            
                              e ? <Grid item xs={6} sm={6} md={2}>
                                  <div  style={{position: "relative"}}>
                                    <img src={e instanceof File || e instanceof Blob ? URL.createObjectURL(e) : 
                                      `${IMG_BASE_URL}${e}`}
                                      alt="Uploaded preview" style={{ width:"100px",height:"90px", borderRadius:"10px" }} />
                                    <IconButton aria-label="delete" style={{position: "absolute",left: "0px"}} 
                                    onClick={()=>{
                                      deleteImage(index,imageIndex)
                                    }} >
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  </div>
                            </Grid>  :null
                              
                        )
                        })
                      :null
                    }
                  </Grid>
              </Grid>
             
            </Grid>
          </AttributeContainer>
        ))}
      <Box sx={{textAlign:"center",marginBottom:"10px"}}>
        <label style={{color:"red"}}>{errorMessage}</label>
      </Box>
<Button onClick={addAttribute} variant="outlined" sx={{ marginRight: 2,color:HEADING_COLOR ,borderColor:HEADING_COLOR}}>
              Add Attribute
            </Button>
        <ButtonCust onClick={handleSubmit} sx={BUTTON_STYLE}>
          Submit
        </ButtonCust> &nbsp;
        <ButtonCust  sx={CANCEL_BUTTON_STYLE} onClick={()=>{
           navigate('/admin/product_list');
        }}>
          Cancel
        </ButtonCust>
      </FormDiv>
    </Container>
  );
};
export default ProductForm;
