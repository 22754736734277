import axios from "axios";
import {restApi} from "../../../../constants/api";
export const get_product_search = async (search:string = "") => {
        const response = await restApi(`product/admin/search?search=${search}`,"GET",{});
        return response 
};

export const create_order = async (payload:any) => {
        const response = await restApi(`orders`,"POST",payload);
        return response 
};

export const order_list = async (page:number, limit:number, startDate:string | null  = "", endDate:string | null = "") => {
        const response = await restApi(`orders?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`,"GET");
        return response 
};

export const order_list_total = async (search:string = "") => {
        const response = await restApi(`orders/total?search=${search}`,"GET");
        return response 
};

export const order_detail = async (orderId:string = "") => {
        const response = await restApi(`orders/${orderId}`,"GET");
        return response 
};

export const order_status_update = async (payload:any,orderId:string) =>{
        const response = await restApi(`/orders/${orderId}/status`,"PATCH",payload);
        return response 
}

export const get_units = async () =>{
        const response = await restApi(`/units/conversions`,"GET");
        return response 
}
export const get_gst_rates = async () =>{
        const response = await restApi(`/gst`,"GET");
        return response 
}

export const get_user = async () =>{
        const response = await restApi(`/users/search`,"GET");
        return response 
}

export const create_user = async (payload:any) =>{
        const response = await restApi(`/users/create`,"POST",payload);
        return response 
}
 