// export function calculateGStPrice(orderQty: any, gst_percentage: any, attributes: any) {
//     // Add GST percentage to the attributes object
//     attributes.gst_percentage = gst_percentage;
    
//     // Calculate GST per unit price and total GST price, ensuring fixed decimals
//     let gst_per_price: number = parseFloat((Number(attributes.price_per_unit) * (Number(gst_percentage) / 100)).toFixed(2));
//     let gst_price_total = (gst_per_price * orderQty).toFixed(2);
    
//     // Update attributes with GST and final prices
//     attributes.gst_per_price = gst_per_price;
//     attributes.gst_price_total = parseFloat(gst_price_total);
    
//     attributes.mrpPrice = (parseFloat(attributes.mrpPrice_per_unit) + attributes.gst_per_price).toFixed(2);
//     attributes.price = (parseFloat(attributes.price_per_unit) + attributes.gst_per_price).toFixed(2);
    
//     // Convert string values back to numbers
//     attributes.mrpPrice = parseFloat(attributes.mrpPrice);
//     attributes.price = parseFloat(attributes.price);
    
//     return attributes;
// }

// export function calculateGStPrice(
//     orderQty: number, 
//     gst_percentage: number = 0, 
//     attributes: any, 
//     unitvalue: any, 
//     units: any[]
//   ) {
//     console.log("gst_percentage",gst_percentage)
//     // Use attributes.unit if unitvalue is undefined or null
//     const selectedUnitValue = unitvalue ?? attributes.unit;
  
//     // Find unit details based on the selected unit value
//     const unit_details = units && units.length > 0 
//       ? units.filter((e: any) => e.unit === selectedUnitValue) 
//       : [];
  
//     const conversion_factor = unit_details && unit_details.length > 0 
//       ? Number(unit_details[0].conversion_factor) 
//       : 1;
  
//     // Calculate the base price per unit after applying the conversion factor
//     const base_price_per_unit = Number(attributes.price_per_unit);
//     const base_mrpPrice_per_unit = Number(attributes.mrpPrice_per_unit);
    
//     const converted_price_per_unit = parseFloat((conversion_factor * base_price_per_unit).toFixed(2));
//     const converted_mrpPrice_per_unit = parseFloat((conversion_factor * base_mrpPrice_per_unit).toFixed(2));
  
//     // Calculate GST per unit price and total GST price
//     const gst_per_price = parseFloat((converted_price_per_unit * (gst_percentage / 100)).toFixed(2));
//     const gst_price_total = parseFloat((gst_per_price * orderQty).toFixed(2));
  
//     // Calculate final prices including GST
//     const final_price = parseFloat((converted_price_per_unit + gst_per_price).toFixed(2));
//     const final_mrpPrice = parseFloat((converted_mrpPrice_per_unit + gst_per_price).toFixed(2));
  
//     // Update the attributes object with calculated prices
//     attributes.gst_percentage = gst_percentage;
//     attributes.gst_per_price = gst_per_price;
//     attributes.gst_price_total = gst_price_total;
//     attributes.mrpPrice = final_mrpPrice;
//     attributes.price = final_price;
  
//     return attributes;
//   }

export function calculateGStPrice(
    orderQty: number, 
    gst_percentage: number = 0, 
    attributes: any, 
    unitvalue: any, 
    units: any[]
  ) {
    // Use attributes.unit if unitvalue is undefined or null
    const selectedUnitValue = unitvalue ?? attributes.unit;
  
    // Find unit details based on the selected unit value
    const unit_details = units && units.length > 0 
      ? units.filter((e: any) => e.unit === selectedUnitValue) 
      : [];
  
    const conversion_factor = unit_details && unit_details.length > 0 
      ? Number(unit_details[0].conversion_factor) 
      : 1;
  
    // Calculate the base price per unit after applying the conversion factor
    const base_price_per_unit = Number(attributes.price_per_unit);
    const base_mrpPrice_per_unit = Number(attributes.mrpPrice_per_unit);
    console.log("conversion_factor",conversion_factor)
    // Apply the conversion factor and manage decimal precision
    const converted_price_per_unit = parseFloat((conversion_factor * base_price_per_unit).toFixed(4));
    const converted_mrpPrice_per_unit = parseFloat((conversion_factor * base_mrpPrice_per_unit).toFixed(4));
    console.log("converted_price_per_unit",converted_price_per_unit)
    // Calculate GST per unit price and manage decimal precision
    const gst_per_price = parseFloat((converted_price_per_unit * (gst_percentage / 100)).toFixed(4));
    console.log("gst_per_price",gst_per_price,gst_percentage)
    const gst_price_total = parseFloat((gst_per_price * orderQty).toFixed(4));
  
    // Calculate final prices including GST
    const final_price = parseFloat((converted_price_per_unit + gst_per_price).toFixed(4));
    const final_mrpPrice = parseFloat((converted_mrpPrice_per_unit + gst_per_price).toFixed(4));
  
    // Update the attributes object with calculated prices
    attributes.gst_percentage = gst_percentage;
    attributes.gst_per_price = gst_per_price;
    attributes.gst_price_total = gst_price_total;
    attributes.mrpPrice = final_mrpPrice;
    attributes.price = final_price;
    console.log("attributes",attributes)
    return attributes;
  }
  
  

  export function convertNumberToWords(num: number): string {
    const a: string[] = [
        '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven',
        'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'
    ];
    const b: string[] = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

    const convertHundreds = (n: number): string => {
        if (n === 0) return '';
        if (n < 20) return a[n] + ' ';
        if (n < 100) return b[Math.floor(n / 10)] + ' ' + a[n % 10] + ' ';
        return a[Math.floor(n / 100)] + ' Hundred ' + convertHundreds(n % 100);
    };

    const convertToWords = (n: number): string => {
        if (n === 0) return 'Zero';

        let output = '';

        if (Math.floor(n / 100000) > 0) {
            output += convertHundreds(Math.floor(n / 100000)) + 'Lakh ';
            n %= 100000;
        }

        if (Math.floor(n / 1000) > 0) {
            output += convertHundreds(Math.floor(n / 1000)) + 'Thousand ';
            n %= 1000;
        }

        output += convertHundreds(n);
        return output.trim();
    };

    // Split the number into integer and fractional part
    const integerPart = Math.floor(num);
    const fractionalPart = Math.round((num - integerPart) * 100); // for two decimal places

    let result = convertToWords(integerPart);

    if (fractionalPart > 0) {
        result += ' and ' + convertToWords(fractionalPart) + ' Paise';
    }

    return result + ' Only';
}

